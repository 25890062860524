@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html,
body {
  max-width: 100vw;
  /* font-family: 'Montserrat', sans-serif !important; */
  /* overflow-x: hidden; */
}

/* .title {
  font-family: 'Montserrat', sans-serif !important;
} */

.nav-link:hover {
  color: #03b403
}

.nav-btn {
  height: 40px;
  /* border-bottom: 3px solid white !important; */
  background-color: white !important;
}

.nav-btn:hover {
  background-color: white !important;
  color: #03b403;
}

.nav-btn-selected {
  height: 40px;
  position: relative;
  background-color: white !important;
  /* border-bottom: 3px solid #03b403 !important; */
}

.nav-btn-selected::after{
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  height: 3px;
  bottom:0;
  background-color: #03b403;
  animation-name: borderAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes borderAnimation {
  0% {
    width: 0px;
    left: 50%;
  }

  100% {
    width: 100%;
    left: 0;
  }
}

.nav-btn-selected:hover {
  color: #03b403;
}

.bg-img-accueil {
  background-image: url('../public/images/bg-img-accueil.jpg');
  background-size: cover;
  background-position: top center;
  /* box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.1);  */
}

.bg-img {
  background-image: url('../public/images/tertiaire bureau 3.jpg');
  background-size: cover;
  background-position: center center;
  /* box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.1);  */
}

.bg-img-dark {
  background-image: url('../public/images/home-sofitexgroup.jpg');
  background-size: cover;
  background-position: center center;
  box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.4); 
}

.bg-img-brands {
  background-image: url('../public/images/bg_history.jpg');
  background-size: cover;
  background-position: center center;
  /* box-shadow: inset 2500px 0 0 0 rgba(68, 165, 23, 0.3);  */
}

.animated-title {
  animation-name: opacityAnimation;
  animation-duration: 3s;
  animation-timing-function: ease-in;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.card-animation:hover {
  /* transform: translateY(-15px); */
  transition-duration: 0.5s;
}

.card-animation:hover {
  transform: translateY(-15px);
  /* transition-duration: 0.5s; */
}

.story-card {
  transition: transform 100ms ease-in-out;
  /* box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.4);  */
}

.story-card:hover {
  transform: scale(1.1);
  z-index: 20;
}

/* .story-img {
  box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.6) !important; 
} */

main {
  overflow-x: hidden;
}

.logo-animation {
  transition-duration: 1s;
}

.logo-animation:hover {
  transform: scale(1.1);
  z-index: 20;
}

.agency-link:hover {
  color: #03b403
}

/* Pagination */
.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  outline: none;
  max-width: 100vw;
  min-height: fit-content;
}
.pagination > .active > a {
  background-color: #03b403;
  border-color: #03b403;
}
.pagination > li > a,
.pagination > li > span {
  font-weight: bold;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;
  margin: 0 3px;
  /* color: #03b403 !important; */
  color: #2f2f2f !important;
  border-radius: 4px;
  border: 1px solid #03b403;
  font-size: normal;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: white;
  color: white !important;
  background-color: #03b403;
  outline: none;
  border: 1px solid #03b403;
}
.pagination > li > a,
.pagination > li > span {
  color: #03b403;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 4px;
  font-weight: bold;
  font-size: medium;
  padding: 10px 16px !important;
  font-size: normal;
}

.pagination > li {
  margin-top: 25px;
}

.pagination > li > a:hover {
  color: white !important;
  background-color: #03b403;
  outline: none;
  outline: 1px solid #03b403;
}

.pagination > li:first-child > a:hover,
.pagination > li:last-child > a:hover {
  color: #03b403;
}

input[type=file]::file-selector-button {
  padding: 9px;
  border-radius: 0.375rem;
  border: none;
  background-color: white;
  transition: 0.5s;
  color: black;
}

input[type=file]::file-selector-button:hover {
  background-color: #e2e8f0;
}

input[type=file] > span {
  color: white !important;
}

.css-1akget7 {
  margin-top: 5px !important;
  background-color: white;
}

.link {
  color: #03b403
}

.link:hover {
  color: #00ed00
}